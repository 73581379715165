(function()
{
	'use strict';
	
	jQuery(function()
	{
		jQuery.dispendium.form(
		{
			fncOnSuccess: function(objJSONResponse, objForm)
			{
				objForm[0].reset();
				
				objForm.prev('#form-notification').slideDown(250);
			}
		});
		
		jQuery(window).on('load resize', function()
		{
			var intHeightPeak = 0;
			
			jQuery('#circulation figcaption').each(function()
			{
				if (jQuery(this).outerHeight(true) > intHeightPeak)
				{
					intHeightPeak = jQuery(this).outerHeight(true);
				}
			});
			
			jQuery('#circulation figcaption').css('min-height', intHeightPeak + 'px');
		});
	});
}());