(function()
{
	"use strict";

	jQuery.dispendium = jQuery.extend(jQuery.dispendium,
	{
		form: function(objSettings)
		{
			objSettings = jQuery.extend(
			{
				strSelector: 'form[target!="_self"]',
				strErrorSelector: '',
				strLoadingSelector: 'input[type="submit"] ~ #loader',
				fncBeforeSubmit: null,
				fncAfterSubmit: null,
				fncOnError: null,
				fncOnSuccess: null,
				debug: false
			}, objSettings);
			
			var arrRequests = [];
			
			jQuery(document).on('submit', objSettings.strSelector, function()
			{
				var objForm = jQuery(this);
				
				if (objSettings.debug === true &&
					typeof objForm.attr('action') !== 'string')
				{
					console.error('Formular hat keine action!');
					
					return (false);
				}
				
				if (objSettings.debug === true &&
					typeof objForm.attr('id') !== 'string')
				{
					console.error('Formular hat keine ID');
					
					return (false);
				}
				
				var objFormId = objForm.attr('id');
				
				if (typeof arrRequests[objFormId] === 'object' &&
					(arrRequests[objFormId].readyState !== 4 && arrRequests[objFormId].readyState !== 0))
				{
					return (false);
				}
				
				if (typeof objSettings.fncBeforeSubmit === 'function')
				{
					var objReturn = objSettings.fncBeforeSubmit(objForm);
					
					if (objReturn === false)
					{
						return (false);
					}
				}
				
				arrRequests[objFormId] = jQuery.ajax(
				{
					url: objForm.attr('action'),
					type: objForm.attr('method'),
					data: new FormData(this),
				    processData: false,
				    contentType: false,
					
					beforeSend: function()
					{
						jQuery('.dispendium-label-error').remove();
						jQuery('.dispendium-input-error').removeClass('dispendium-input-error error');
						
						objForm.find('input[type="submit"]').css('visibility', 'hidden');
						objForm.find(objSettings.strLoadingSelector).show();
					},
					
					error: function()
					{
						if (typeof objSettings.fncOnError === 'function')
						{
							objSettings.fncOnError(objForm);
						}
						
						delete arrRequests[objFormId];
					},
					
					success: function(mixResponse)
					{
						if (mixResponse.length === 0)
						{
							if (objSettings.blnDebug === true)
							{
								console.error('Response ist leer');
							}
							
							return;
						}
						
						var objJSONResponse = typeof mixResponse === 'object' ? mixResponse : {};
						
						if (typeof mixResponse === 'string')
						{
							try
							{
								objJSONResponse = jQuery.parseJSON(mixResponse);					
							}
							catch (objException)
							{
								if (objSettings.blnDebug === true)
								{
									console.error('Response konnte nicht als JSON verarbeitet werden');
								}
								
								return;
							}
						}
						
						if (objJSONResponse.status === 'success' &&
							typeof objSettings.fncOnSuccess === 'function')
						{
							objSettings.fncOnSuccess(objJSONResponse, objForm);
						}
						else if (objJSONResponse.status === 'error')
						{
							jQuery.each(objJSONResponse.error, function(strKey, mixMessage)
							{
								var objMessages = {};
								
								if (typeof mixMessage === 'string')
								{
									objMessages[0] = mixMessage;
								}
								else
								{
									objMessages = mixMessage;
								}
								
								jQuery.each(objMessages, function(intKey, strMessage)
								{
									jQuery('label[for="' + strKey + '"]').append('<div class="dispendium-label-error error">' + strMessage + '</div>');
								});
								
								if ( ! jQuery('#' + strKey).hasClass('dispendium-input-error'))
								{
									jQuery('#' + strKey).addClass('dispendium-input-error error');
									
									if (jQuery('#' + strKey).is(':hidden') &&
										jQuery('#' + strKey).is('textarea'))
									{
										jQuery('#' + strKey).prev('.froala-box').addClass('dispendium-input-error error');
									}
									
									if (jQuery('#' + strKey).is(':hidden') &&
										jQuery('#' + strKey).is('select'))
									{
										jQuery('#' + strKey).next('.select2').addClass('dispendium-input-error error');
									}
								}
							});
							
							jQuery('.dispendium-input-error:first').focus();
							
							delete arrRequests[objFormId];
						}
						else if (typeof objJSONResponse.redirect === 'string')
						{
							window.location = objJSONResponse.redirect;
						}
					},
					
					complete: function()
					{
						objForm.find('input[type="submit"]').css('visibility', 'visible');
						objForm.find(objSettings.strLoadingSelector).hide();
					}
				});
				
				return (false);
			});
		}
	});
}());